<template>
  <div class="reimburseView">
    <detail-top 
      :tabsList="tabsList" 
      :createTime="detailInfo.createTime"
      :headerTitle="`查看返费请款详情`"
      :title="`${detailInfo.createUserName || ''}提交的返费请款审批申请`"
    >
    </detail-top>
    <div :class="[auditAuthority == 1 ? 'on-approval' : '', 'content-wrap']">
      <my-card name="1" title="基础信息">
        <div class="basics-wrap">
          <my-cell title="申请单号" :value="detailInfo.billNo" />
          <my-cell title="发起部门" :value="detailInfo.departmentName" />
          <my-cell title="返费所属月份" :value="detailInfo.billMonth" />
          <my-cell title="请款事由" :value="detailInfo.reasons" />
          <my-cell title="所属渠道供应商" :value="detailInfo.supplierName" />
          <my-cell title="付款日期" :value="detailInfo.payDate" />  
          <my-cell title="付款方式" :value="detailInfo.paymentMethod === 1 ? '转账' : '现金'" />
          <my-cell title="付款单位" :value="detailInfo.companyName" />
          <my-cell title="实发金额" :value="`￥${detailInfo.totalAmount}`" />
          <my-cell title="附件总金额"  :value="`￥${fileTotalAmount}`" />
          <my-cell title="备注" :value="detailInfo.remark" />
          <img v-if="statusImg" class="status-img" :src="statusImg" />
        </div>
      </my-card>

      <my-card name="2" title="返费明细">
        <template v-slot:right>
          共计{{ detailsTotal }}条
        </template>
        <div class="details-list-wrap">
          <detail-item v-for="(item,index) in detailsList" :key="index" :detail="item" :index="index+1" />
          <div v-if="isUnfold" class="unfold-wrap" @click="handleUnfold">
            <div class="unfold">展开</div>
            <van-icon name="arrow-down" />
          </div>
        </div>
      </my-card>

      <my-card name="3" title="附件">
        <my-files-view :fileList="detailInfo.files"/>
      </my-card>
      <approval-process name="4" v-if="detailInfo.billNo" :billId="detailId" :module="1" />
    </div>
    <detail-approval-btn v-if="detailInfo.billNo && auditAuthority == 1" :billNo="detailInfo.billNo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon  } from 'vant';
import NP from "number-precision";
import passImg from '@/assets/approvalPassImg.png'
import rejectImg from '@/assets/approvalRejectImg.png'

Vue.use(NavBar).use(Icon);
import {
  reqRefundFeeDetail
} from "../../api/reimburse";
export default {
  name: "reimburseView",
  data() {
    return {
      detailId: '', // id
      tabsList: [
        {
          title: '基础信息',
          key: 1,
        },
        {
          title: '返费明细',
          key: 2,
        },
        {
          title: '附件',
          key: 3,
        },
        {
          title: '审批流程',
          key: 4,
        },
      ],
      detailInfo: {},
      detailsList: [],
      isUnfold: false,
      detailsTotal: 0,
      statusImg: '',
      auditAuthority: 0,
      fileTotalAmount: 0,
    };
  },
  components: {
    ApprovalProcess: () => import('@/components/business/approvalProcess/index.vue'),
    DetailTop: () => import('@/components/business/detailTop/index.vue'),
    MyCard: () => import('@/components/basics/MyCard.vue'),
    MyCell: () => import('@/components/basics/MyCell.vue'),
    MyFilesView: () => import('@/components/basics/MyFilesView.vue'),
    DetailItem: () => import('./components/RebateItem.vue'),
    DetailApprovalBtn: () => import('@/components/business/detailApprovalBtn/index.vue'),
  },
  mounted() {
    // 查看是否有内容
    this.detailId = this.$route.query.id;
    this.auditAuthority = this.$route.query.auditAuthority;
    this.resendAuditAuthority = this.$route.query.resendAuditAuthority;
    if (this.detailId) {
      this.getDetailInfo()
    } else {
      console.log("打开错误");
    }
  },
  methods: {
    getDetailInfo() {
      reqRefundFeeDetail({id: this.detailId}).then(res => {
        let obj = res.resultData;
        this.detailInfo = obj;
        let list = obj.refundFees;
        this.detailsTotal = list.length
        if(list.length > 3){
          this.detailsList = list.slice(0, 3);
          this.isUnfold = true;
        } else {
          this.detailsList = list;
        }
        if(obj.status === 2){ // 驳回
          this.statusImg = rejectImg;
        } else if(obj.status === 3){ // 通过
          this.statusImg = passImg;
        }
        let total = 0;
        let amountList = [];
        obj.refundFees.map(item => {
          amountList.push(item.refundFeeAmount)
        })
        if(obj.refundFees.length > 1){
          total = NP.plus(...amountList)
        } else {
          total = amountList[0]
        }
        this.fileTotalAmount = total;
      })
    },
    handleUnfold(){
      this.detailsList = this.detailInfo.refundFees;
      this.isUnfold = false;
    },
    handleCutPage(){
      this.$router.replace({
        name: 'failureRetryView',
        query: {
          id: this.detailId,
          auditAuthority: this.auditAuthority,
          resendAuditAuthority: this.resendAuditAuthority
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.reimburseView{
  .content-wrap{
    overflow: auto;
    height: calc(100vh - 172px);
    .basics-wrap{
      position: relative;
      .status-img{
        width: 50%;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }
    }
    .details-list-wrap{
      .unfold-wrap{
        color: $gray;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .unfold{
          margin-right: 4px;
        }
      }
    }
  }
  >.on-approval{
    height: calc(100vh - 214px);
  }
}

</style>